import { render, staticRenderFns } from "./tiaokuan.vue?vue&type=template&id=19ed7601&"
import script from "./tiaokuan.vue?vue&type=script&lang=js&"
export * from "./tiaokuan.vue?vue&type=script&lang=js&"
import style0 from "./tiaokuan.vue?vue&type=style&index=0&id=19ed7601&prod&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports